var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FinalExecutionStatusBasic = exports.ExecutionStatusBasic = void 0;
var ExecutionStatusBasic;
(function (ExecutionStatusBasic) {
  ExecutionStatusBasic["Unknown"] = "Unknown";
  ExecutionStatusBasic["Pending"] = "Pending";
  ExecutionStatusBasic["Failure"] = "Failure";
})(ExecutionStatusBasic = exports.ExecutionStatusBasic || (exports.ExecutionStatusBasic = {}));
var FinalExecutionStatusBasic;
(function (FinalExecutionStatusBasic) {
  FinalExecutionStatusBasic["NotStarted"] = "NotStarted";
  FinalExecutionStatusBasic["Started"] = "Started";
  FinalExecutionStatusBasic["Failure"] = "Failure";
})(FinalExecutionStatusBasic = exports.FinalExecutionStatusBasic || (exports.FinalExecutionStatusBasic = {}));
export default exports;